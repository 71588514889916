<section>
    <header>
        <h1>Felhasználó törlése</h1>
    </header>
    <label for="delete">Felhasználó id:</label>
    <input type="text" id="delete" #inputName>

    <button (click)=deleteUserById()>Törlés</button>

    <header>
        <h1>Felhasználó adatainak felfrissitése</h1>
    </header>

    <label for="update">Felhasználó id:</label>
    <input type="text" id="update" #updateInput>
    <button class="update-button" (click)="sendIdToUpdate()">Adatlap</button>


</section>