import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

 @ViewChild('inputName') inputName:any;
 @ViewChild('updateInput') updateInput:any;

  constructor(private fireStore: AngularFirestore, private router: Router) { }

  ngOnInit(): void {
  }

 

  deleteUserById = () => {
    const id = this.inputName.nativeElement.value;
    this.fireStore.collection('customers').doc(id).delete()
    .then(() => console.log('Deleted user with id: ', id))
    .then(() => this.router.navigate(['user-list']))
    .catch(err => console.log(err))
  }


  sendIdToUpdate = () => {
   const userId = this.updateInput.nativeElement.value;
  //  console.log(userId); 
  this.router.navigate(['user', userId]);
  }

}
