<section>
    <form [formGroup]="userForm" (ngSubmit)="saveUser()">
        <label for="username">Név:</label>
        <input type="text" id="username" name="username" formControlName="username">

        <label for="age">Kor:</label>
        <input type="number" id="age" name="age" formControlName="age">

        <label for="email">Email:</label>
        <input type="email" name="email" id="email" formControlName="email">

        <label for="gender">Nem:</label>
        <select name="gender" id="gender" formControlName="gender">
            <option value="" disabled>Válasz</option>
            <option value="male">Férfi</option>
            <option value="female">Nő</option>
            <option value="other">Egyéb</option>
        </select>
        <button type="submit"> {{buttonName}} </button>
    </form>
</section>