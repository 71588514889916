// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyD6xP0TU5MhhTfhtJpBChyO-BeGSiLmiOM",
   authDomain: "customer-angular-8945c.firebaseapp.com",
   projectId: "customer-angular-8945c",
   storageBucket: "customer-angular-8945c.appspot.com",
   messagingSenderId: "233510134626",
   appId: "1:233510134626:web:fcbb5b920313b129fb423b",
   measurementId: "G-2GZM2EBEF0"   }  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
