import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { UserModel } from 'src/app/model/userModel';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  userList: Array<UserModel> = [];

  constructor(private fireStore: AngularFirestore, private router: Router) { }

  id: any = '';

  ngOnInit(): void {
    this.fireStore.collection('customers').valueChanges({ idField: 'id' }).subscribe(      // az idField objektum lekéri az id-t is! és id -nek elnevezi
      // this.fireStore.collection('customers').valueChanges().subscribe(    // Nem kéri le az id-t
      (data: any) => {
        this.userList = data;
        console.log(this.userList);
      },
      (err) => {
        console.log(err);
      },
      () => { },

    )
  }

  deleteUserById = (id) => {
    this.fireStore.collection('customers').doc(id).delete()
      .then(() => console.log('Deleted user with id: ', id))
      .catch(err => console.log(err))
  }

  update = (id) => {
    const userId = id;
    //  console.log(userId); 
    this.router.navigate(['user', userId]);
  }

}
