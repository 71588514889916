import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserModel } from 'src/app/model/userModel';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  userForm: FormGroup;
  buttonName: string = 'Mentés';
  updateUser: any;
  userId: string;     // update esetén kell

  constructor(
    private fireStore: AngularFirestore,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.userForm = new FormGroup({
      'username': new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(15)]),
      'age': new FormControl('', [Validators.required, Validators.pattern('/^[0-9]{2}$/')]),
      'email': new FormControl('', [Validators.required, Validators.email]),
      'gender': new FormControl('', Validators.required)
    });

    // ez itt egy kicsit bonyolult és nem tiszta még:

    this.activatedRoute.paramMap.subscribe(     // paramMap -ben tárolódnak az id-k, Firebase leírássból utánanézni
      (params: any) => {
        const id = params.get('id');    // app-routing.module.ts -ben: user/:id  innen az id
        if (id) {                       //  létezik-e az id ?
          this.fireStore.collection('customers').doc(id).get().subscribe(
            (doc: any) => {
              if (doc.exists) {          // létezik-e a doc ?
                this.updateUser = doc.data();
                // console.log(this.updateUser);  // itt valódi id -vel kell tesztelni, elején ne legyen space!
                this.userForm.patchValue(this.updateUser);
                this.userId = id;                    // ha updatel, a userid értéket kap (új usernél nincs értéke)
                this.buttonName = 'frissítés';       // Frissítésnél ez lesz a gomb neve
              }
            }
          )
        }
      }
    );

  }

  saveUser = () => {
    const user = this.userForm.value;
    if (this.updateUser) {                // először megnézi, van-e értéke az updateUser -nek, akkor updateln kell, egyébként új user
      this.updateUserFromForm(this.userId, user);  // user tartalmazza az új adatokat
      return
    }
    this.sendUserToStore(user);          // ha új a user menti, user tartalmazza az adatokat
  }

  sendUserToStore = (user: UserModel) => {
    const response = this.fireStore.collection('customers').add(user);
    response
      .then((data) => {
        console.log('User saved with: ', data.id);   // új user felvételét jelzi a consolon
      })
      .then(() => {
        this.userForm.reset();                     // kitörli az űrlapot
        this.router.navigate(['user-list']);       // meghívja a user-list -et
      })
      .catch(err => console.log(err))
  }

  updateUserFromForm = (userId: string, userData: any) => {
    this.fireStore.collection('customers').doc(userId).set(userData)
      .then(() => console.log('User updated'))     // user update-et jelzi a consolon
      .then(() => {
        this.userForm.reset();                     // kitörli az űrlapot
        this.router.navigate(['user-list']);       // meghívja a user-list -et
      })
      .catch(err => console.log(err))
  }

}
