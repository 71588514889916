<header>
    <h1>Felhasználok listája:</h1>
</header>

<section>
    <div class="table-holder">
        <table>
            <tr>
                <th>Név:</th>
                <th>Kor:</th>
                <th>Email:</th>
                <th>Nem:</th>
            </tr>
            <tbody>
                <tr *ngFor="let user of userList">
                    <td>{{user.username}}</td>
                    <td>{{user.age}}</td>
                    <td>{{user.email}}</td>
                    <td>{{user.gender}}</td>
                    <td><button (click)="update(user.id)">Update</button></td>
                    <td><button (click)="deleteUserById(user.id)">Delete</button></td>
                </tr>
            </tbody>
        </table>

    </div>

</section>